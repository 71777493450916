import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { createSelectorHook, useDispatch } from 'react-redux';
import { loginedReducer } from './logined';
import { userReducer } from './user';
import { usageRecordsReducer } from './usageRecords';
import { organizationReducer } from './organization';
import { usagesReducer } from './usages';
import { initReadReducer } from './initRead';
import { typeBOrganizationReducer } from './typeBOrganization';
import { typeBUsagesReducer } from './typeBUsages';

const reducer = combineReducers({
  logined: loginedReducer,
  user: userReducer,
  usageRecords: usageRecordsReducer,
  organization: organizationReducer,
  usages: usagesReducer,
  initRead: initReadReducer,
  typeBOrganization: typeBOrganizationReducer,
  typeBUsages: typeBUsagesReducer,
});

export const store = configureStore({
  reducer
});
type RootState = ReturnType<typeof store.getState>;

export const useTypedSelector = createSelectorHook<RootState>();
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
