import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { useTypedSelector } from '../../redux';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { StateUser } from '../../redux/user';

type SimpleAppBarProps = {
  handleMenu?: ()=>{},
  iconMenu?: React.ReactChild,
  headerTitle?: React.ReactChild,
  logined: boolean,
  menus: Array<{value: string, label: string, subMenus?: Array<{label: string, path: string}>}>
  user: StateUser
}
export const AppFrame = (props: any) => {
  const logined = useTypedSelector((state) => state.logined);
  const user = useTypedSelector((state) => state.user);
  return (
    <>
    <SimpleAppBar
      iconMenu={props.iconMenu}
      headerTitle={props.headerTitle}
      logined={logined}
      menus={props.menus}
      user={user}
    />
      {logined ?
        <Wrap
        logined={logined}
        >
          <Content>{props.children}</Content>
        </Wrap> :
        <Wrap
        logined={logined}>{props.children}</Wrap>
      }
    </>
  );
};
const SimpleAppBar:React.VFC<SimpleAppBarProps> = (props) => {
  const handleMenu = props.handleMenu ? props.handleMenu : () => {};
  const user = props.user
  return (
    <>
    <StyledAppBar color="primary" elevation={0}>
      <Toolbar>
        <StyledIconButton
          onClick={() => handleMenu()}
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          <MenuIcon />
        </StyledIconButton>
        <AppLogo>{props.headerTitle}</AppLogo>
        {(props.logined && user.userId) && <BarContents>{props.iconMenu}</BarContents>}
      </Toolbar>
    </StyledAppBar>
    </>
  );
};

const StyledAppBar = styled(AppBar)`
  @media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}px) {
    margin-left: ${(props) => props.theme.drawerWidth};
  }
  background: #000626
`;

const StyledIconButton = styled(IconButton)`
  @media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}px) {
    display: none;
  }
`;

const Content = styled.div`
  @media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}px) {
    max-width: calc(100% - ${(props) => props.theme.drawerWidth});
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
  }
`;

const BarContents = styled(Toolbar)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const AppLogo = styled.div`
display: flex;
justify-content: flex-start;
width: 100%;
`;

type CustomProps = {
  logined: boolean
}

const Wrap = styled.div<CustomProps>`
width: 100%;
min-height: ${props => props.logined ? `calc(100vh)`: `100vh`};
background: #F3F7FA;
`;
