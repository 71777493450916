import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useTypedSelector } from '../../redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import NotFoundPage from './NotFoundPage';
import { SimpleBackdrop } from '../molecules/Backdrop';

const TopPage = lazy(() => import('./TopPage'));
const LoginPage = lazy(() => import('./LoginPage'));

type Page = {
  path: string;
  component: JSX.Element;
  isPubulic: boolean;
}

const pages: Page[] = [
  { path: '/', component: <TopPage/>, isPubulic: false },
  { path: '/login', component: <LoginPage />, isPubulic: true },
];

export const Pages = () => {
  const logined = useTypedSelector((state) => state.logined);
  const user = useTypedSelector((state) => state.user);
  const initRead = useTypedSelector((state) => state.initRead);
  const checkAuth = (isPublic: boolean, page: any) => {
    if (isPublic) {
      return page;
    } else {
      if(logined){
        if(user.userId){
          return page
          
        } else {
          return <></>
        }
      } else {
        return <LoginPage/>
      }
    }
  }
  return (
    <Router>
      <Switch>
      {initRead ? pages.map((item, index) => (
        <Route
          key={index}
          exact
          path={item.path}
          component={() => <Suspense fallback={<LinearProgress />}>{checkAuth(item.isPubulic, item.component)}</Suspense>}
        />
      )) : <SimpleBackdrop open={true} message={'読み込み中..'}/>}
      <Route component={() => <NotFoundPage/>} /> 
      </Switch>
    </Router>
  );
};