import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../../common-types/firebase/firestore/users';

export type StateUser = Omit<User, 'createAt' | 'updateAt'> & {createAtMillis: number, updateAtMillis: number, userId: string}

const initState: StateUser = {
  organizationId: '',
  email: '',
  createdBy: '',
  updatedBy: '',
  isDeleted: false,
  photoUrl: '',
  nameKanji: '',
  nameKana: '',
  createAtMillis:0,
  updateAtMillis: 0,
  userId: '',
  beneficiaryNumber: '',
  serviceType: 'aType',
}

const user = createSlice({
  name: 'user',
  initialState: initState,
  reducers: {
    setUser: (_state: StateUser, action: PayloadAction<StateUser>) => {
      return action.payload;
    },
    resetUser: () => {
      return initState;
    }
  }
});

export const { setUser, resetUser } = user.actions;
export const userReducer = user.reducer;
