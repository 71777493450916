import { createTheme } from "@material-ui/core";

export const theme = createTheme({
  palette: {
    primary: {
      main: '#4963EC',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ED6164',
      contrastText: '#FFFFFF',
    },
    black: {
      textColor: '#2B2B2B'
    },
    gray: {
      gray01: `#817C7C`,
    },
    buttonColor: {
      red: '#FF413B',
      green: '#FF413B',
      orange: '#FF413B'
    },

    mode: 'light'
  },
  typography: {
    fontSize: 14,
    fontFamily: ['Noto Sans JP', 'sans-serif'].join(',')
  },
  drawerWidth: '240px',
  tableHeight: '950px',
  gutter: { pc: '64px', sp: '56px' }
})
