import { useEffect } from 'react';
import { SimpleBackdrop } from '../molecules/Backdrop';
import { useHistory } from 'react-router-dom';

const NotFoundPage = () => {
    const history = useHistory();
    useEffect(() => {
        history.push('/');
    }, [history]);
    return(
        <>
        <SimpleBackdrop
            open={true}
            message={'ページ読み込み中'}
        />
        </>
    )

}

export default NotFoundPage;
