import { createSlice } from '@reduxjs/toolkit';

const initReadSlice = createSlice({
  name: 'initRead',
  initialState: false,

  reducers: {
    setInitRead: () => {
      return true;
    },
  }
});

export const { setInitRead } = initReadSlice.actions;
export const initReadReducer = initReadSlice.reducer;
