import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import styled from "styled-components"
import { Typography } from '@material-ui/core';
import { RakucoTitleLogo } from '../../icons';

export const AppLogo = (props: any) => {
  return(
    <ListItem button>
     <Icon>
        {/*ここにアイコンのコンポを追加可能*/}
      </Icon>
      <Text primary={<StyledText><RakucoTitleLogo/></StyledText>}/>
   </ListItem>
  )
}

const Text = styled(ListItemText)`
  color: white;
  text-decoration: none;
`;

const Icon = styled(ListItemIcon)`
  color: white;
`;

const StyledText = styled(Typography)`
font-family: Futura;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 29px;
letter-spacing: 0em;
text-align: left;
`