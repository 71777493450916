import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UsageRecord } from 'common-types/firebase/firestore/organizations/usageRecords';

export type PeriodMillis = {
  startAtMillis: number,
  endAtMillis: number | null,
}

export type WorkRecord = {wasWorked: boolean,
  startAtMillis: number | null,
  endAtMillis: number| null,
  breakTime?: number | null,
  totalWorkTime?: number | null
}

export type StateUsageRecord = Omit<UsageRecord,
'attendanceRecord' |
'createAt' |
'updateAt' |
'workRecord'
> &
{
  attendanceRecord:
    {
      startAtMillis: number,
      endAtMillis: number| null,
      breakTime: PeriodMillis[],
    } ,
  workRecord: WorkRecord,
  createAtMillis: number,
  updateAtMillis: number,
  usageRecordId: string,
  };
const initialState: StateUsageRecord[] = [{
  userId: '',
  serviceProvisionStatus: 'comingToOffice',
  attendanceRecord: {
    startAtMillis: 0,
    endAtMillis: null,
    breakTime: [],
  },
  //mealOffer: '',
  foodservice: false,
  transferService: 'none',
  transferWithSameSite: 'none',
  visitSupport: '',
  medicalCooperation: {
    supportType: null,
    wasTookLessonForSputumSuction: false,
  },
  wasTookSupportForHouseLife: false,
  wasTookSupportForSocialLife: false,
  wasLocalSupportAdditional: false,
  note: '',
  workRecord: {
    wasWorked: false,
    startAtMillis: null,
    endAtMillis: null,
  },
  memo: '',
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: '',
  isDeleted: false,
  usageRecordId: '',
  supportForExperienceUserType: 1,
  isCommunityLifeSupportBase: false,
  isRemoteWork: false,
  wasPeerSupportAddtional: false,
  wasTookEmergencyReceiving: false, // Add this property
  wasTookIntensiveSupport: false, // Add this property
}];

const usageRecordsSlice = createSlice({
  name: 'usageRecords',
  initialState,
  reducers: {
    setUsageRecords: (_state: StateUsageRecord[], action: PayloadAction<StateUsageRecord[]>) => {
      return action.payload;
    },
    resetUsageRecords:(): StateUsageRecord[] => {
      return initialState;
    }
  }
});

export const { setUsageRecords, resetUsageRecords } = usageRecordsSlice.actions;
export const usageRecordsReducer = usageRecordsSlice.reducer;
