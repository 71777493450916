import { useEffect, useCallback } from 'react';
import { useAppDispatch } from '../redux';
import { auth, firestore } from './index';
import { login, logout } from '../redux/logined';
import { resetUser, setUser } from '../redux/user';
import { ServiceType, User } from '../../../common-types/firebase/firestore/users';
import { resetUsageRecords, setUsageRecords, StateUsageRecord } from '../redux/usageRecords';
import { UsageRecord } from 'common-types/firebase/firestore/organizations/usageRecords';
import  dayjs  from '../dayjs';
import { Organization } from 'common-types/firebase/firestore/organizations';
import { resetOrganization, setOrganization } from '../redux/organization';
import { TypeBUsages, Usages } from 'common-types/firebase/firestore/users/settings';
import { resetUsages, setUsages } from '../redux/usages';
import * as admin from "firebase-admin";
import { setInitRead } from '../redux/initRead';
import { resetTypeBOrganization, setTypeBOrganization } from '../redux/typeBOrganization';
import { TypeBOrganization } from 'common-types/firebase/firestore/typeBOrganization';
import { resetTypeBUsages, setTypeBUsages } from '../redux/typeBUsages';
//import { setUid } from '../modules/uid';
//import { firestore,analytics } from '../firebase'
/*type Work = {
  id: string;
  title: string;
  photo: string;
  url: string;
};*/

export const FirebaseToRedux = () => {
  const dispatch = useAppDispatch();

  const observeUsageRecords = useCallback((organizationId: string, userId: string, serviceType: ServiceType) => {
    const selectedOrganization = serviceType === 'aType' ? 'organizations' : 'typeBOrganizations';
    firestore.collection(selectedOrganization).doc(organizationId).collection('usageRecords').where('userId', '==', userId).where('attendanceRecord.startAt','>=',new Date(dayjs().startOf('day'))).onSnapshot((querySnapshot) => {
      const list: StateUsageRecord[] = [];
      querySnapshot.forEach(async (doc) => {
        const usageRecordsQuerySnapshot = doc.data() as UsageRecord;
        const res = usageRecordsQuerySnapshot.attendanceRecord?.breakTime ? usageRecordsQuerySnapshot.attendanceRecord?.breakTime.map((n: {
          startAt: admin.firestore.Timestamp;
          endAt: admin.firestore.Timestamp | null;
      }) => {
          return {
            startAtMillis: n.startAt.toMillis(),
            endAtMillis: n.endAt ? n.endAt.toMillis() : null,
          }
        }) : [];
        list.push({
          userId: usageRecordsQuerySnapshot.userId,
          serviceProvisionStatus: usageRecordsQuerySnapshot.serviceProvisionStatus,
          attendanceRecord: {
            startAtMillis: usageRecordsQuerySnapshot.attendanceRecord.startAt.toMillis(),
            endAtMillis: usageRecordsQuerySnapshot.attendanceRecord?.endAt ? usageRecordsQuerySnapshot.attendanceRecord?.endAt.toMillis() : null,
            breakTime: res,
          },
          // mealOffer: usageRecordsQuerySnapshot.mealOffer,
          foodservice: usageRecordsQuerySnapshot.foodservice,
          transferService: usageRecordsQuerySnapshot.transferService,
          transferWithSameSite: usageRecordsQuerySnapshot.transferWithSameSite,
          visitSupport: usageRecordsQuerySnapshot.visitSupport,
          medicalCooperation: usageRecordsQuerySnapshot.medicalCooperation,
          wasTookSupportForHouseLife: usageRecordsQuerySnapshot.wasTookSupportForHouseLife,
          wasTookSupportForSocialLife: usageRecordsQuerySnapshot.wasTookSupportForSocialLife,
          wasLocalSupportAdditional: usageRecordsQuerySnapshot.wasLocalSupportAdditional,
          note: usageRecordsQuerySnapshot.note,
          memo: usageRecordsQuerySnapshot.memo,
          workRecord: {
            wasWorked: usageRecordsQuerySnapshot.workRecord.wasWorked,
            startAtMillis: usageRecordsQuerySnapshot.workRecord.startAt ? usageRecordsQuerySnapshot.workRecord.startAt.toMillis() : null,
            endAtMillis: usageRecordsQuerySnapshot.workRecord.endAt ? usageRecordsQuerySnapshot.workRecord.endAt.toMillis() : null,
          },
          createAtMillis: usageRecordsQuerySnapshot.createAt.toMillis(),
          updateAtMillis: usageRecordsQuerySnapshot.updateAt.toMillis(),
          createdBy: usageRecordsQuerySnapshot.createdBy,
          updatedBy: usageRecordsQuerySnapshot.updatedBy,
          isDeleted: usageRecordsQuerySnapshot.isDeleted,
          supportForExperienceUserType: usageRecordsQuerySnapshot.supportForExperienceUserType,
          isCommunityLifeSupportBase: usageRecordsQuerySnapshot.isCommunityLifeSupportBase,
          usageRecordId: doc.id,
          isRemoteWork: usageRecordsQuerySnapshot.isRemoteWork === undefined ? false : usageRecordsQuerySnapshot.isRemoteWork,
          wasPeerSupportAddtional: usageRecordsQuerySnapshot.wasPeerSupportAddtional === undefined ? false : usageRecordsQuerySnapshot.wasPeerSupportAddtional,
          wasTookEmergencyReceiving: usageRecordsQuerySnapshot.wasTookEmergencyReceiving === undefined ? false : usageRecordsQuerySnapshot.wasTookEmergencyReceiving,
          wasTookIntensiveSupport: usageRecordsQuerySnapshot.wasTookIntensiveSupport === undefined ? false : usageRecordsQuerySnapshot.wasTookIntensiveSupport,
        });
      });
      dispatch(setUsageRecords(list));
    });
  }, [dispatch]);

  const observeOrganization = useCallback((
    organizationId: string,
    serviceType: ServiceType
  ) => {
    const selectedOrganization = serviceType === 'aType' ? 'organizations' : 'typeBOrganizations';
    console.log(selectedOrganization)
    firestore.collection(selectedOrganization).doc(organizationId).onSnapshot((doc) => {
      if(doc.exists){
        if (serviceType === 'aType') {
          const organizationQuerySnapshot = doc.data() as Organization;
          const {
            createAt,
            updateAt,
            ...params
          } = organizationQuerySnapshot;
          dispatch(setOrganization({
            ...params,
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
          }));
          dispatch(resetTypeBOrganization());
        } else {
          const organizationQuerySnapshot = doc.data() as TypeBOrganization;
          const {
            createAt,
            updateAt,
            ...params
          } = organizationQuerySnapshot;
          dispatch(setTypeBOrganization({
            ...params,
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
          }));
          dispatch(resetOrganization());
        }
      } else {
        dispatch(resetOrganization());
        dispatch(resetTypeBOrganization());
      }
    });
  }, [dispatch]);


  const observeUsagesSetting = useCallback(async(
    userId: string,
    serviceType: ServiceType
  ) => {
    const usages = serviceType === 'aType' ? 'usages' : 'typeBUsages';
    firestore.collection('users').doc(userId).collection('settings').doc(usages).onSnapshot((usagesDocumentData) => {
      if(usagesDocumentData.exists){
        if(serviceType === 'aType') {
          const usagesDocumentSnapshot =  usagesDocumentData.data() as Usages
          const {
            createAt,
            updateAt,
            hasReceivedService,
            hasReceivedCertification,
            supportPlanStatus,
            supportForEmploymentTransition,
            specialCaseForUsage,
            intensiveSupport,
            ...params
          } = usagesDocumentSnapshot;
          dispatch(setUsages({
            ...params,
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
            hasReceivedService: {
              startAtMillis: hasReceivedService.startAt ? hasReceivedService.startAt.toMillis() : null,
              endAtMillis: hasReceivedService.endAt ? hasReceivedService.endAt.toMillis() : null,
            },
            hasReceivedCertification: {
              startAtMillis: hasReceivedCertification.startAt ? hasReceivedCertification.startAt.toMillis() : null,
              endAtMillis: hasReceivedCertification.endAt ? hasReceivedCertification.endAt.toMillis() : null,
            },
            supportPlanStatus: {
              isNotCreated: supportPlanStatus.isNotCreated,
              startAtMillis: supportPlanStatus.startAt ? supportPlanStatus.startAt.toMillis() : null,
            },
            supportForEmploymentTransition: {
              isAdded: supportForEmploymentTransition.isAdded,
              startAtMillis: supportForEmploymentTransition.startAt ? supportForEmploymentTransition.startAt.toMillis() : null,
            },
            specialCaseForUsage: specialCaseForUsage ? { // 元々A型と移行でspecialCaseForUsageが漏れていたためundenedの場合がある
              isSpecialCase: specialCaseForUsage.isSpecialCase,
              startAtMillis: specialCaseForUsage.startAt ? specialCaseForUsage.startAt.toMillis() : null,
              endAtMillis: specialCaseForUsage.endAt ? specialCaseForUsage.endAt.toMillis() : null,
            } : {
              isSpecialCase: false,
              startAtMillis: null,
              endAtMillis: null,
            },
            intensiveSupport: {
              isStarted: intensiveSupport?.isStarted || false,
              startAtMillis: intensiveSupport?.startAt ? intensiveSupport.startAt.toMillis() : null,
            },

          }));
          dispatch(resetTypeBUsages())
        } else {
          const usagesDocumentSnapshot = usagesDocumentData.data() as TypeBUsages
          const {
            createAt,
            updateAt,
            hasReceivedService,
            hasReceivedCertification,
            supportPlanStatus,
            supportForEmploymentTransition,
            specialCaseForUsage,
            ...params
          } = usagesDocumentSnapshot;
          dispatch(setTypeBUsages({
            ...params,
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
            hasReceivedService: {
              startAtMillis: hasReceivedService.startAt ? hasReceivedService.startAt.toMillis() : null,
              endAtMillis: hasReceivedService.endAt ? hasReceivedService.endAt.toMillis() : null,
            },
            hasReceivedCertification: {
              startAtMillis: hasReceivedCertification.startAt ? hasReceivedCertification.startAt.toMillis() : null,
              endAtMillis: hasReceivedCertification.endAt ? hasReceivedCertification.endAt.toMillis() : null,
            },
            supportPlanStatus: {
              isNotCreated: supportPlanStatus.isNotCreated,
              startAtMillis: supportPlanStatus.startAt ? supportPlanStatus.startAt.toMillis() : null,
            },
            supportForEmploymentTransition: {
              isAdded: supportForEmploymentTransition.isAdded,
              startAtMillis: supportForEmploymentTransition.startAt ? supportForEmploymentTransition.startAt.toMillis() : null,
            },
            specialCaseForUsage: {
              isSpecialCase: specialCaseForUsage.isSpecialCase,
              startAtMillis: specialCaseForUsage.startAt ? specialCaseForUsage.startAt.toMillis() : null,
              endAtMillis: specialCaseForUsage.endAt ? specialCaseForUsage.endAt.toMillis() : null,
            }
          }));
          dispatch(resetUsages());

        }
      } else {
        dispatch(resetUsages());
        dispatch(resetTypeBUsages());
      }
    });
  },[dispatch]);

  const observeUser = useCallback((uid: string) => {
    firestore.collection('users').doc(uid).onSnapshot((doc) => {
      const userQuerySnapshot = doc.data() as User;
      if(doc.exists){
      const { createAt, updateAt, ...params} = userQuerySnapshot;
      const serviceType = userQuerySnapshot.serviceType ? userQuerySnapshot.serviceType: 'aType';
      dispatch(setUser({
        ...params,
        serviceType: serviceType,
        createAtMillis: userQuerySnapshot.createAt.toMillis(),
        updateAtMillis: userQuerySnapshot.updateAt.toMillis(),
        userId: doc.id
      }));
     
      observeUsageRecords(params.organizationId, uid, serviceType);
      observeUsagesSetting(uid, serviceType);
      observeOrganization(params.organizationId, serviceType);
     }
    })
    }, [dispatch, observeOrganization, observeUsageRecords, observeUsagesSetting]);

  const observeAuth = useCallback(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        dispatch(login());
        observeUser(user.uid);
      } else {
        dispatch(logout());
        dispatch(resetUser());
        dispatch(resetUsages());
        dispatch(resetUsageRecords());
        //dispatch(setUid(''));
      }
      setTimeout(() => {
        dispatch(setInitRead());
      }, 500);
    });
  }, [dispatch, observeUser]);

  useEffect(() => {
    observeAuth();
  }, [observeAuth]);


  return null;
}
