import './firebase/index'
import { ThemeProvider as MaterialThemeProvider, StylesProvider } from "@material-ui/styles";
import * as React from 'react';
import { Provider } from 'react-redux';
import { store } from './redux';
import { Pages } from './components/pages';
import { FirebaseToRedux } from './firebase/FirebaseToRedux';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme';
import { HelmetProvider } from 'react-helmet-async';
import { GlobalStyle } from './styles/GlobalStyle';
import { AppFrame } from './components/templates/AppFrame';
import { HeaderTitleLogo, IconMenu} from './components/molecules/MenuList';
import { AppLogo } from './components/atoms/AppLogo';

function App() {
  return (
    <StylesProvider injectFirst>
      <MaterialThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <HelmetProvider>
            <Provider store={store}>
              <FirebaseToRedux />
              <AppFrame
                  appLogo={<AppLogo />}
                  iconMenu={<IconMenu/>}
                  headerTitle={<HeaderTitleLogo/>}
                >
              <Pages />
              </AppFrame>
            </Provider>
          </HelmetProvider>
        </ThemeProvider>
      </MaterialThemeProvider>
    </StylesProvider>
  );
}

export default App;